(function () {
	"use strict";
	window.requestAF = (function () {
		return window.requestAnimationFrame ||
			window.webkitRequestAnimationFrame ||
			window.mozRequestAnimationFrame ||
			function (callback) { return window.setTimeout(callback, 17) }
	})();

	window.cancelAF = (function () {
		return window.cancelAnimationFrame ||
			window.webkitCancelAnimationFrame ||
			window.mozCancelAnimationFrame ||
			function (id) { return window.clearTimeout(id) }
	})();

	if (jQuery) {
		var oldOn = jQuery.fn.on, oldOff = jQuery.fn.off;
		//
		jQuery.fn._tfuncs = {};
		jQuery.fn.on = function (a, b, c, d, e) {
			if (a === 'touchend') {
				var i, f, n;
				for (i = arguments.length - 1; i >= 0; i--) {
					if (arguments[i] && typeof arguments[i] === 'function') {
						f = arguments[i];
						n = f.name || 'anonymous';
						this._tfuncs[n] = function (x) { x.preventDefault(); x.stopPropagation(); f(x) };
						arguments[i] = this._tfuncs[n]
						oldOn.apply(this, arguments);
						break;
					}
				};
				return oldOn.apply(this, ['mouseup', b, c, d, e]);
			} else {
				return oldOn.apply(this, arguments);
			}
		};
		//
		jQuery.fn.off = function (a, b, c) {
			if (a === 'touchend') {
				var i, n;
				for (i = arguments.length - 1; i >= 0; i--) {
					if (arguments[i] && typeof arguments[i] === 'function') {
						n = arguments[i].name || 'anonymous';
						arguments[i] = this._tfuncs[n];
						oldOff.apply(this, arguments);
						delete this._tfuncs[n];
						break;
					}
				};
				return oldOff.apply(this, ['mouseup', b, c]);
			} else {
				if (arguments.length === 0) this._tfuncs = {};
				return oldOff.apply(this, arguments);
			}
		};
	};

	/* ********* app.NewComponent **************
	* returns base component element for non jquery based elements
	* dependencies: Tweenlite for css
	*/
	var td = (function () {
		return (('ontouchstart' in window)
			|| (navigator.MaxTouchPoints > 0)
			|| (navigator.msMaxTouchPoints > 0));
	})(),
		//down = "onpointerdown" in document.documentElement ? "pointerdown" : undefined,
		//move = "onpointermove" in document.documentElement ? "pointermove" : undefined,
		up = /*"onpointerup" in document.documentElement ? "pointerup" : */undefined;
	//
	//if(!down) down = td?'touchstart':'mousedown';
	//if(!move) move = td?'touchmove':'mousemove';
	if (!up) up = td ? 'touchend' : 'mouseup';
	//	
	Object.defineProperty(app, 'isTouchDevice', {
		get: function () { return td },
		set: function () { throw new Error('app.isTouchDevice is a read only property.') }
	});
	//
	Object.defineProperty(app, 'RELEASE', {
		get: function () { return up },
		set: function () { throw new Error('app.RELEASE is a read only property.') }
	});
	//
	window.app.NewComponent = function (tag, attrObj, isSVG) {
		var el = isSVG ? document.createElementNS('http://www.w3.org/2000/svg', tag) : document.createElement(tag);

		for (var a in attrObj) el.setAttribute(a, attrObj[a]);
		el._data = { isSVG: isSVG || false };
		el._data.ceh = {};
		//
		el.css = function (arg1, arg2) {
			if (arg1 && arg2 !== undefined) {
				this.style[arg1] = arg2;
				return this;
			} else {
				if (typeof arg1 === 'string') {
					var p = this.style[arg1];
					if (p === '' || p === undefined) p = window.getComputedStyle(this, null).getPropertyValue(arg1);
					return p;
				} else {
					TweenLite.set(this, arg1);
					return this;
				}
			}
		}
		// args: event [,optional: data to be passed], handler
		el.on = function (e, a1, a2) {
			if (a2) {
				var t = a2.name || 'anonymous',
					f = e === 'touchend' ? function (e) { e.preventDefault(); e.data = a1; a2(e) } : function (e) { e.data = a1; a2(e) };
				this._data.ceh[t] = f;
				this.addEventListener(e, f);
				if (e === 'touchend') this.addEventListener('mouseup', f);
			} else {
				if (e === 'touchend') {
					var t = a1.name || 'anonymous',
						f = function (e) { e.preventDefault(); a1(e) };
					this._data.ceh[t] = f;
					this.addEventListener(e, f);
					this.addEventListener('mouseup', a1);
				} else {
					this.addEventListener(e, a1);
				}
			}
			return this;
		}
		el.off = function (e, h) {
			var t = h.name || 'anonymous';
			if (this._data.ceh[t]) {
				this.removeEventListener(e, this._data.ceh[t]);
				if (e === 'touchend') this.removeEventListener('mouseup', this._data.ceh[t]);
				delete this._data.ceh[t];
			} else {
				this.removeEventListener(e, h);
				if (e === 'touchend') this.removeEventListener('mouseup', h);
			}
			return this;
		}
		el.attr = function (arg1, arg2) {
			if (typeof arg1 === 'string') {
				if (arg2) this.setAttribute(arg1, arg2);
				else return this.getAttribute(arg1);
			} else {
				for (var a in arg1) this.setAttribute(a, arg1[a]);
			}
			return this;
		}
		if (isSVG && tag === 'svg') el.attr({ xmlns: "http://www.w3.org/2000/svg", "xmlns:xlink": "http://www.w3.org/1999/xlink" });
		el.removeAttr = function (arg1) {
			if (typeof arg1 === 'string') {
				this.removeAttribute(arg1);
			} else {
				for (var a in arg1) this.removeAttribute(a, arg1[a]);
			}
			return this;
		}
		el.show = function () {
			showEl(this);
			return this;
		}
		el.hide = function () {
			hideEl(this);
			return this;
		}
		el.toggleShow = function () {
			if (this.style.display === 'none' || window.getComputedStyle(this, null).getPropertyValue('display') === 'none') showEl(this); else hideEl(this);
			return this;
		}
		el.remove = function () {
			this.parent().removeChild(this);
			return this;
		}
		el.parent = function () {
			return this.parentNode || this.parentElement;
		}
		el.addClass = function (str) {
			addClass(this, arguments);
			return this;
		}
		el.removeClass = function (str) {
			removeClass(this, arguments);
			return this;
		}
		if (!isSVG && tag !== 'canvas' && tag !== 'video') el.height = function (val) {
			if (val) {
				el.css({ height: val });
				return this
			} else {
				return el.clientHeight;
			}
		}
		if (!isSVG && tag !== 'canvas' && tag !== 'video') el.width = function (val) {
			if (val) {
				el.css({ width: val });
				return this
			} else {
				return el.clientWidth;
			}
		}
		el.data = function (str, value) {
			if (str && value) {
				setData(this, str, value);
			} else {
				return getData(this, str);
			}
			return this;

		}
		return el;
	};

	function getData(el, attr) {
		if (el.dataset !== undefined) return el.dataset[attr];
		else return el.getAttribute('data-' + attr);
	};

	function setData(el, attr, val) {
		if (el.dataset !== undefined) el.dataset[attr] = val;
		else el.setAttribute('data-' + attr, val);
	};

	function addClass(el, args) {
		if (el.classList) {
			Array.prototype.reverse.call(args);
			for (var i = args.length - 1; i >= 0; i--) {
				el.classList.add(args[i]);
			};
		} else {
			Array.prototype.reverse.call(args);
			var cn = el._data.isSVG ? el.className.baseVal : el.className,
				arr = cn.split(' '),
				d;
			for (var i = args.length - 1; i >= 0; i--) {
				d = true;
				for (var j = arr.length - 1; j >= 0; j--) {
					if (arr[j] === args[i]) d = false;
				};
				if (d) cn = cn.concat(' ' + args[i]);
			};
			el._data.isSVG ? el.className.baseVal = cn : el.className = cn;
		}
	};

	function removeClass(el, args) {
		if (el.classList) {
			Array.prototype.reverse.call(args);
			for (var i = args.length - 1; i >= 0; i--) {
				el.classList.add(args[i]);
			};
		} else {
			Array.prototype.reverse.call(args);
			var cn = el._data.isSVG ? el.className.baseVal : el.className;
			arr = cn.split(' ');
			for (var n = args.length - 1; n >= 0; n--) {
				var d;
				for (var i = arr.length - 1; i >= 0; i--) {
					if (arr[i] === args[n]) {
						d = i;
						break;
					}
				};
				if (d) {
					arr.splice(d, 1);
					el._data.isSVG ? el.className.baseVal = arr.toString().replace(/,/g, ' ') : el.className = arr.toString().replace(/,/g, ' ');
				};
			}
		}
	};

	function showEl(el) {
		el.style.display = el._data['olddisplay'] || '';
		// find default display
		if (!el._data['olddisplay']) {
			var d = window.getComputedStyle(el, null).getPropertyValue('display');
			if (d === 'none') {
				var selectors = [el.tagName.toLowerCase()], cn = el.className.split(' ');
				for (var c = 0; c < cn.length; c++) cn[c] = '.'.concat(cn[c]);
				if (cn[0] !== '') selectors = selectors.concat(cn);
				if (el.id !== '') selectors.push('#' + el.id);
				for (var i = 0; i < document.styleSheets.length; i++) {
					var sheet = document.styleSheets[i];
					if (sheet.rules) for (var j = 0; j < sheet.rules.length; j++) {
						for (var n = 0; n < selectors.length; n++) {
							if (sheet.rules[j].selectorText) {
								var arr = sheet.rules[j].selectorText.split(',');
								for (var a = 0; a < arr.length; a++) {
									if (arr[a] === selectors[n]) {
										if (sheet.rules[j].style.display === 'none') {
											sheet.rules[j].style.display = '';
											el._data['olddisplay'] = window.getComputedStyle(el, null).getPropertyValue('display');
											sheet.rules[j].style.display = 'none';
											//console.log(sheet.rules[j]);
										}

									}
								}
							}
						}

					}
				}
			} else {
				el._data['olddisplay'] = d;
			}
			el.style.display = el._data['olddisplay'] || '';
		}
		return el.style.display;
	};

	function hideEl(el) {
		if (!el._data['olddisplay']) {
			var d = el.style.display !== '' ? el.style.display : window.getComputedStyle(el, null).getPropertyValue('display');
			if (d !== 'none') el._data['olddisplay'] = d;
		}
		return el.style.display = 'none';
	};

}());

/* ********* app.UIBtn **************
* returns ui button
* dependencies: Tweenlite
*/
(function () {
	"use strict";
	//
	function click(e) {
		if (app.sounds.click) app.sounds.click.play({ pan: app.getPan(app.offset(e.target).left) });
	}
	//
	window.app.UIBtn = function (text, href, icon, color, inDiv, isHidden) {
		if (!createjs.Sound.loadComplete(app.audioDomain + 'universal/noteOn.mp3')) {
			createjs.Sound.registerSounds([
				{ id: 'noteOn', src: 'noteOn.mp3' },
				{ id: 'noteOff', src: 'noteOff.mp3' }
			], app.audioDomain + 'universal/')
		};
		//
		var uibtn = app.NewComponent(inDiv && inDiv === true ? 'div' : 'button'),
			bColor = color ? color : 'blue',
			bIcon = icon ? icon : 'none',
			cssVars = { transformOrigin: "center center" };

		// props & methods
		uibtn.componentType = 'UIBtn';
		uibtn.isOn = true;
		uibtn.oldHide = uibtn.hide;
		uibtn.oldShow = uibtn.show;
		uibtn.oldToggleShow = uibtn.toggleShow;
		uibtn.oldRemove = uibtn.remove;
		uibtn.oldOn = uibtn.on;
		uibtn.oldOff = uibtn.off;

		uibtn.on = function (e, a1, a2) {
			this.oldOn(e, click);
			return this.oldOn(e, a1, a2);
		}

		uibtn.off = function (e, h) {
			this.oldOff(e, click);
			return this.oldOff(e, h);
		}

		uibtn.remove = function (withFade) {
			this.isOn = false;
			if (withFade && withFade === true) {
				if (!app.sounds.noteOff) app.sounds.noteOff = createjs.Sound.createInstance('noteOff');
				if (!app.sounds.noteOff.play({ pan: app.getPan(app.offset(this).left), volume: 0.5 })) createjs.Sound.play('noteOff', { pan: app.getPan(app.offset(this).left), volume: 0.5 });
				TweenLite.to(this, .4, { opacity: 0, scaleX: .9, scaleY: .9, ease: Back.easeIn, onComplete: this.oldRemove, onCompleteScope: this });
			} else {
				this.oldRemove();
			}
		}

		uibtn.show = function (delay) {
			this.removeAttribute('disabled');
			if (!app.sounds.noteOn) app.sounds.noteOn = createjs.Sound.createInstance('noteOn');
			TweenLite.to(this, .4, {
				opacity: 1, delay: delay || 0, ease: Quad.easeOut, onStartScope: this,
				onStart: function () {
					this.oldShow();
					if (!app.sounds.noteOn.play({ pan: app.getPan(app.offset(this).left), volume: 0.5 })) createjs.Sound.play('noteOn', { pan: app.getPan(app.offset(this).left), volume: 0.5 });
				}
			});
			TweenLite.to(this, .5, { scaleX: 1, delay: delay || 0, scaleY: 1, ease: Back.easeOut, overwrite: 0 });
			this.isOn = true;
			return this;
		}

		uibtn.hide = function (delay, dontHide) {
			if (!app.sounds.noteOff) app.sounds.noteOff = createjs.Sound.createInstance('noteOff');
			TweenLite.to(this, .4, {
				opacity: 0, delay: delay || 0, scaleX: .9, scaleY: .9, ease: Back.easeIn, onStartScope: this,
				onStart: function () {
					if (!app.sounds.noteOff.play({ pan: app.getPan(app.offset(this).left), volume: 0.5 })) createjs.Sound.play('noteOff', { pan: app.getPan(app.offset(this).left), volume: 0.5 });
				},
				onComplete: function () {
					if (!dontHide) this.target.oldHide();
				}
			});
			this.isOn = false;
			return this;
		}

		uibtn.toggleShow = function (delay, dontHide) {
			if (this.isOn) this.hide(delay, dontHide); else this.show(delay);
			return this;
		}

		uibtn.dim = function (alpha, scale) {
			alpha = alpha ? alpha : .5;
			scale = scale ? scale : 1;
			this.setAttribute('disabled', 'disabled');
			TweenLite.to(this, .2, { opacity: alpha, scaleX: scale, scaleY: scale });
			this.isOn = false;
			return this;
		}

		uibtn.text = function (str) {
			var c = this.children[0].className;;
			this.innerHTML = '<span class="' + c + '"></span>' + str;
			return this;
		}

		uibtn.icon = function (str) {
			var span = this.tagName === 'DIV' ? this.children[0].children[0] : this.children[0];
			span.className = (str === "none" ? '' : 'btn-icon-sprite ' + str);
			return this;
		}

		uibtn.color = function (str) {
			if (this.tagName === 'DIV') this.children[0].className = 'act-btn act-btn-' + str;
			else this.className = 'act-btn act-btn-' + str;
			return this;
		}

		uibtn.href = function (str) {
			this.on(app.RELEASE, function () { window.location.href = str });
			return this;
		}

		if (inDiv && inDiv === true) {
			uibtn.className = 'act-btn-div';
			var btn = document.createElement('button');
			btn.className = 'act-btn act-btn-' + bColor;
			btn.innerHTML = '<span class="' + (bIcon === "none" ? '' : 'btn-icon-sprite ' + bIcon) + '"></span>' + text;
			uibtn.appendChild(btn)
		} else {
			uibtn.className = 'act-btn act-btn-' + bColor;
			uibtn.innerHTML = '<span class="' + (bIcon === "none" ? '' : 'btn-icon-sprite ' + bIcon) + '"></span>' + text;
		}

		if (isHidden && isHidden === true) {
			cssVars.opacity = 0;
			cssVars.scaleX = .9;
			cssVars.scaleY = .9;
			uibtn.oldHide();
			uibtn.isOn = false;
		};
		uibtn.css(cssVars);
		if (href) uibtn.href(href);
		return uibtn;
	};

}());

/* ********* app.LoadAnim **************
* canvas object
* dependencies: TweenLite, TimelineLite
*/
(function () {
	"use strict";
	//default rgbArray is [0,0,0] (black).
	window.app.LoadAnim = function (rgbArray) {
		var cvs = app.NewComponent('canvas', { class: 'act-load-anim', width: 146, height: 32 }),
			i, context = cvs;
		if (!rgbArray) rgbArray = [0, 0, 0];
		// props
		cvs.componentType = 'LoadAnim';
		cvs.ctx2D = cvs.getContext("2d");
		cvs.ctx2D.globalAlpha = 0;
		cvs.dotArray = [];
		cvs.numDots = 5;
		cvs.alphaMin = .6;
		cvs.alphaMax = 1;
		cvs.oldShow = cvs.show;
		cvs.oldHide = cvs.hide;
		cvs.ticker = undefined;
		cvs.sequence = new TimelineLite({ onComplete: reverse, onCompleteParams: ["{self}"], onReverseComplete: restart, onReverseCompleteParams: ["{self}"], paused: true });
		// methods
		cvs.show = function () {
			this.oldShow();
			this.ticker = requestAF(function () { onTick(context) });
			this.sequence.play();
			TweenLite.to(this.ctx2D, .6, { globalAlpha: 1, ease: Sine.easeOut });
			return this;
		}
		cvs.hide = function (remove) {
			var ctx = this;
			TweenLite.to(this.ctx2D, .6, {
				globalAlpha: 0, ease: Sine.easeOut, onCompleteScope: ctx,
				onComplete: function () {
					this.sequence.pause();
					this.ticker = cancelAF(this.ticker);
					this.oldHide();
					if (remove) this.remove();
				}
			});
			return this;
		}
		// create dots
		for (i = 0; i < cvs.numDots; i++) {
			var dot;
			if (i == 0) {
				dot = new Dot(3, 3, 1, 1)
			} else {
				dot = new Dot((28 * i) + 3, 3, cvs.alphaMin, .8)
			};
			dot.fillColor = "rgba(" + rgbArray.toString() + ",alpha)";
			dot.draw(cvs.ctx2D);
			cvs.dotArray.push(dot);
		}
		// write sequence
		for (i = 0; i < cvs.dotArray.length; i++) {
			if (i == 0) {
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i], .2, { alpha: cvs.alphaMin, scale: .8, ease: Sine.easeOut }));
			}
			else if (i == cvs.dotArray.length - 1) {
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i], .2, { alpha: cvs.alphaMax, scale: 1, ease: Sine.easeOut }), -.24);
			}
			else {
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i], .2, { alpha: cvs.alphaMax, scale: 1, ease: Sine.easeOut }), -.24);
				cvs.sequence.append(TweenLite.to(cvs.dotArray[i], .2, { alpha: cvs.alphaMin, scale: .8, ease: Sine.easeOut, delay: .2 }), -.24);
			};
		}
		return cvs;
	};

	function onTick(context) {
		context.ctx2D.clearRect(0, 0, 146, 32);
		for (var i = 0; i < context.numDots; i++) {
			context.dotArray[i].draw(context.ctx2D);
		};
		context.ticker = requestAF(function () { onTick(context) });
	};

	function reverse(sequence) {
		sequence.reverse();
	};

	function restart(sequence) {
		sequence.restart();
	};

	Dot.prototype.fillColor = "rgba(0, 0, 0, alpha)";

	function Dot(posX, posY, alpha, scale) {
		this.x = posX;
		this.y = posY;
		this.alpha = alpha;
		this.scale = scale;
		this.radius = 14;
	};

	Dot.prototype.draw = function (ctx2D) {
		ctx2D.fillStyle = this.fillColor.replace("alpha", (this.alpha).toString());
		ctx2D.beginPath();
		ctx2D.arc(this.x + this.radius, this.y + this.radius, this.radius * this.scale, 0, 2 * Math.PI, false);
		ctx2D.fill();
		ctx2D.closePath();
	};

}());

/* ********* app.Note **************
* Note made with css
* dependencies: tweenlite
*/
(function () {
	"use strict";
	/* Note params
	*	text:String;
	*	direction:String = "bottom";  (where the arrow points) = "top","bottom","left","right"
	*	position:Number = 2; (position of arrow ie left,center,right or top,center,bottom. 2 = center) = 1,2,3;
	*/
	window.app.Note = function (text, direction, position, isOn) {
		if (!createjs.Sound.loadComplete(app.audioDomain + 'universal/noteOn.mp3')) {
			createjs.Sound.registerSounds([
				{ id: 'noteOn', src: 'noteOn.mp3' },
				{ id: 'noteOff', src: 'noteOff.mp3' }
			], app.audioDomain + 'universal/')
		};
		//
		var el = app.NewComponent('div', { class: 'act-note-div' });
		el.note = app.NewComponent('div', { class: 'act-note' });
		el.note.innerHTML = '<p class="act-note-text">' + text + '</p>';
		el.appendChild(el.note);
		el.isOn = isOn !== undefined ? isOn : true;
		el.oldHide = el.hide;
		el.oldShow = el.show;
		el.oldRemove = el.remove;
		if (el.isOn) TweenLite.set(el, { transformOrigin: "center center" });
		else TweenLite.set(el, { opacity: 0, scaleX: .9, scaleY: .9, display: 'none', transformOrigin: "center center" });

		switch (direction) {
			case "top":
				el.note.addClass("act-note-top");
				break;
			case "bottom":
				el.note.addClass("act-note-bottom");
				break;
			case "left":
				el.note.addClass("act-note-left");
				break;
			case "right":
				el.note.addClass("act-note-right");
				break;
			default:
				el.note.addClass("act-note-bottom");
				break;
		};

		switch (position) {
			case 1:
				if (direction === "top" || direction === "bottom") el.note.addClass("act-note-tb-1");
				else if (direction === "left" || direction === "right") el.note.addClass("act-note-lr-1");
				break;
			case 2:
				if (direction === "top" || direction === "bottom") el.note.addClass("act-note-tb-2");
				else if (direction === "left" || direction === "right") el.note.addClass("act-note-lr-2");
				break;
			case 3:
				if (direction === "top" || direction === "bottom") el.note.addClass("act-note-tb-3");
				else if (direction === "left" || direction === "right") el.note.addClass("act-note-lr-3");
				break;
			default:
				// default in css is 50% (2)
				break;
		};

		el.text = function (str) {
			this.note.innerHTML = '<p class="act-note-text">' + str + '</p>';
			TweenLite.set(this, { transformOrigin: "center center" });
			return this;
		}

		el.remove = function (withFade, delay) {
			if (withFade && withFade === true) {
				if (!app.sounds.noteOff) app.sounds.noteOff = createjs.Sound.createInstance('noteOff');
				TweenLite.to(this, .3, {
					opacity: 0, scaleX: .9, scaleY: .9, delay: delay || 0, ease: Back.easeIn,
					onCompleteScope: this,
					onComplete: this.oldRemove,
					onStart: function () {
						if (!app.sounds.noteOff.play({ pan: app.getPan(app.offset(this.note).left), volume: 0.5 })) createjs.Sound.play('noteOff', { pan: app.getPan(app.offset(this.note).left), volume: 0.5 });
					},
					onStartScope: this
				});
			} else {
				if (delay) TweenLite.delayedCall(delay, this.oldRemove, null, this);
				this.oldRemove();
			}
			return this;
		}

		el.show = function (delay) {
			if (!app.sounds.noteOn) app.sounds.noteOn = createjs.Sound.createInstance('noteOn');
			if (!this.isOn) {
				this.isOn = true;
				this.oldShow();
				TweenLite.to(this, .4, {
					opacity: 1, delay: delay || 0, ease: Quad.easeOut, onStartScope: this,
					onStart: function () {
						if (!app.sounds.noteOn.play({ pan: app.getPan(app.offset(this.note).left), volume: 0.5 })) createjs.Sound.play('noteOn', { pan: app.getPan(app.offset(this.note).left), volume: 0.5 });
					}
				});
				TweenLite.to(this, .5, { scaleX: 1, delay: delay || 0, scaleY: 1, ease: Back.easeOut, overwrite: 0 });
			}
			return this;
		}

		el.hide = function (delay) {
			if (!app.sounds.noteOff) app.sounds.noteOff = createjs.Sound.createInstance('noteOff');
			if (this.isOn) {
				this.isOn = false;
				TweenLite.to(this, .3, {
					opacity: 0, scaleX: .9, scaleY: .9, delay: delay || 0, ease: Back.easeIn,
					onComplete: this.oldHide,
					onCompleteScope: this,
					onStart: function () {
						if (!app.sounds.noteOff.play({ pan: app.getPan(app.offset(this.note).left), volume: 0.5 })) createjs.Sound.play('noteOff', { pan: app.getPan(app.offset(this.note).left), volume: 0.5 });
					},
					onStartScope: this
				});
			}
			return this;
		}
		return el;
	};

}());


(function () {
	"use strict";
	window.app.Alert = function (text, btnText, icon, removeOnHide) {
		var el = app.NewComponent('div', { class: 'act-alert box-shadow' });
		el.oldHide = el.hide;
		el.oldShow = el.show;
		el._data.remove = removeOnHide;
		el._text = document.createElement('p');
		el._text.innerHTML = text;
		el._text.className = 'act-alert-txt';
		el._okBtn = app.UIBtn((btnText ? btnText : 'OK'), null, (icon ? icon : 'check'));
		el.appendChild(el._text);
		el.appendChild(el._okBtn);
		el._offFunc = undefined;
		el._okFunc = undefined;
		el.oldHide().css({ alpha: 0, scaleX: .8, scaleY: .8, transformOrigin: "center center" });

		function onOk(e) {
			e.data.context.hide(e);
			if (e.data.context._okFunc) e.data.context._okFunc.f.apply(e.data.context._okFunc.s || window, e.data.context._okFunc.a || []);
		}

		el.show = function (delay) {
			this.oldShow();
			TweenLite.to(this, .5, {
				autoAlpha: 1, scaleX: 1, scaleY: 1, delay: delay ? delay : 0, ease: Back.easeOut, onCompleteScope: this, onStartScope: this,
				onComplete: function () {
					this._okBtn.on(app.RELEASE, { context: this }, onOk);
				},
				onStart: function () {
					TweenLite.set(this, { marginTop: -((this.clientHeight / 2) / parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace('px', ''))) + 'em' });
					app.sounds.alertOn.play({ volume: 0.2 });
				}
			});
			return this;
		}

		el.hide = function () {
			this._okBtn.off(app.RELEASE, onOk);
			app.sounds.alertOff.play({ volume: 0.07 });
			createjs.Sound.play('alertOff', { volume: 0.2, delay: 150, interrupt: createjs.Sound.INTERRUPT_LATE });
			TweenLite.to(this, .4, {
				autoAlpha: 0, scaleX: .8, scaleY: .8, ease: Back.easeIn, onCompleteScope: this,
				onComplete: function () {
					if (this._data.remove) this.remove(); else this.oldHide();
					if (this._offFunc) this._offFunc.f.apply(this._offFunc.s || window, this._offFunc.a || []);
				}
			});
			return this;
		}

		el.text = function (txt) {
			this._text.innerHTML = txt;
			return this;
		}

		el.setBtn = function (txt, icon) {
			this._okBtn.innerHTML = '<span class="btn-icon-sprite ' + (icon ? icon : '') + '"></span>' + txt;
			return this;
		};

		el.reset = function () {
			this._offFunc = null;
			this._okFunc = null;
			//text.html('');
			this._okBtn.innerHTML = '<span class="btn-icon-sprite check"></span>OK';
			TweenLite.set(this, { alpha: 0, scaleX: .8, scaleY: .8, display: 'none' });
			return this;
		}

		el.offFunc = function (func, args, scp) { this._offFunc = { f: func, a: args, s: scp }; return this };
		el.okFunc = function (func, args, scp) { this._okFunc = { f: func, a: args, s: scp }; return this };
		return el;
	};

}());

(function () {
	"use strict";
	window.app.AlertWithTimer = function (text, btnText, icon, removeOnHide, minutes, timerDelay) {
		var el = app.NewComponent('div', { class: 'act-alert box-shadow' }),
			to, l, t, m, s, s1, s2, ms,
			time = minutes,
			delay = timerDelay ? timerDelay * 1000 : 0;
		//    
		el.oldHide = el.hide;
		el.oldShow = el.show;
		el._data.remove = removeOnHide;
		el._data.minutes = minutes;
		el._text = document.createElement('p');
		el._text.innerHTML = text;
		el._text.className = 'act-alert-txt';
		el._timer = document.createElement('div');
		el._timer.className = 'timer';
		el._okBtn = app.UIBtn((btnText ? btnText : 'OK'), null, (icon ? icon : 'check'));
		el.appendChild(el._text);
		el.appendChild(el._timer);
		el.appendChild(el._okBtn);
		el._offFunc = undefined;
		el._okFunc = undefined;
		el.oldHide().css({ alpha: 0, scaleX: .8, scaleY: .8, transformOrigin: "center center" });

		function onOk(e) {
			e.data.context.hide(e);
			if (e.data.context._okFunc) e.data.context._okFunc.f.apply(e.data.context._okFunc.s || window, e.data.context._okFunc.a || []);
		}

		el.show = function (delay, startTimer) {
			var st = startTimer != undefined ? startTimer : true;
			setTimer();
			this.oldShow();
			TweenLite.to(this, .5, {
				autoAlpha: 1, scaleX: 1, scaleY: 1, delay: delay ? delay : 0, ease: Back.easeOut, onCompleteScope: this, onStartScope: this,
				onComplete: function () {
					this._okBtn.on(app.RELEASE, { context: this }, onOk);
					if (st) tick();
				},
				onStart: function () {
					TweenLite.set(this, { marginTop: -((this.clientHeight / 2) / parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace('px', ''))) + 'em' });
					app.sounds.alertOn.play({ volume: 0.2 });
				}
			});
			return this;
		}

		el.hide = function () {
			clearTimeout(to);
			this._okBtn.off(app.RELEASE, onOk);
			app.sounds.alertOff.play({ volume: 0.07 });
			createjs.Sound.play('alertOff', { volume: 0.2, delay: 150, interrupt: createjs.Sound.INTERRUPT_LATE });
			TweenLite.to(this, .4, {
				autoAlpha: 0, scaleX: .8, scaleY: .8, ease: Back.easeIn, onCompleteScope: this,
				onComplete: function () {
					if (this._data.remove) this.remove(); else this.oldHide();
					if (this._offFunc) this._offFunc.f.apply(this._offFunc.s || window, this._offFunc.a || []);
				}
			});
			return this;
		}

		el.text = function (txt) {
			this._text.innerHTML = txt;
			return this;
		}

		el.setBtn = function (txt, icon) {
			this._okBtn.innerHTML = '<span class="btn-icon-sprite ' + (icon ? icon : '') + '"></span>' + txt;
			return this;
		};

		el.reset = function () {
			this._offFunc = null;
			this._okFunc = null;
			//text.html('');
			this._okBtn.innerHTML = '<span class="btn-icon-sprite check"></span>OK';
			TweenLite.set(this, { alpha: 0, scaleX: .8, scaleY: .8, display: 'none' });
			return this;
		};

		el.reset = function () {
			this._offFunc = null;
			this._okFunc = null;
			//text.html('');
			this._okBtn.innerHTML = '<span class="btn-icon-sprite check"></span>OK';
			TweenLite.set(this, { alpha: 0, scaleX: .8, scaleY: .8, display: 'none' });
			return this;
		};

		el.setMinutes = function (minutes) {
			el._data.minutes = minutes;
			setTimer();
			return this;
		};

		el.startTimer = function () {
			tick();
			return this;
		};

		function setTimer() {
			time = el._data.minutes || 0;
      s = (time - Math.floor(time)) * 60;
      el._data.secondsRemaining = s;
			if (s < 10) {
				s1 = '0';
				s2 = s;
			} else {
				s1 = s.toString().substr(0, 1);
				s2 = s.toString().substr(1, 1);
			};
			ms = (time * 60);
			el._timer.innerHTML = '<span>' + Math.floor(time) + '</span><span class="colon">:</span><span>' + s1 + '</span><span>' + s2 + '</span>';
		};

		function tick() {
			l = Date.now();
			to = setTimeout(function () {
				t = Date.now();
				ms = Math.round(ms - ((t - l) / 1000));
				m = Math.floor(ms / 60);
				s = Math.floor(ms - (m * 60));
				if (s < 10) {
					s1 = '0';
					s2 = s;
				} else {
					s1 = s.toString().substr(0, 1);
					s2 = s.toString().substr(1, 1);
        }
        el._data.secondsRemaining = ms;
				el._timer.innerHTML = '<span>' + m + '</span><span class="colon">:</span><span>' + s1 + '</span><span>' + s2 + '</span>';
				if (ms <= 0) {
					clearTimeout(to);
				} else {
					app.sounds.meterUp.play({ volume: 0.7 });
					tick();
				}
			}, 1000);
		};

		el.offFunc = function (func, args, scp) { this._offFunc = { f: func, a: args, s: scp }; return this };
		el.okFunc = function (func, args, scp) { this._okFunc = { f: func, a: args, s: scp }; return this };
		return el;
	};

}());

(function () {
	"use strict"; // issues using app.RELEASE when using Chrome on MS Surface. When using mouse form submits, when using touch form does not submit. Changed listeners to use 'mouseup'.
	window.app.Confirm = function (text, removeOnHide) {
		var el = app.NewComponent('div', { class: 'act-alert box-shadow' });
		el.oldHide = el.hide;
		el.oldShow = el.show;
		el._data.remove = removeOnHide;
		el._text = document.createElement('p');
		el._form = app.NewComponent('form');
		el._text.innerHTML = text;
		el._text.className = 'act-alert-txt';
		el._yesBtn = app.UIBtn(app.lang === 'en' ? 'YES' : 'SÍ', null, 'check');
		el._noBtn = app.UIBtn('NO', null, 'close');
		el.appendChild(el._form);
		el._form.appendChild(el._text);
		el._form.appendChild(el._yesBtn);
		el._form.appendChild(el._noBtn);
		el._offFunc = undefined;
		el._okFunc = undefined;
		el.oldHide().css({ alpha: 0, scaleX: .8, scaleY: .8, transformOrigin: "center center" });

		function onYes(e) {
			//console.log('onYes',e.data.context._yesFunc)
			if (!e.data.context._form) e.data.context.hide(e);
			if (e.data.context._yesFunc) e.data.context._yesFunc.f.apply(e.data.context._yesFunc.s || window, e.data.context._yesFunc.a || []);
		};

		function onNo(e) {
			e.data.context.hide(e);
			if (e.data.context._noFunc) e.data.context._noFunc.f.apply(e.data.context._noFunc.s || window, e.data.context._noFunc.a || []);
		};

		function onThird(e) {
			if (!e.data.context._form) e.data.context.hide(e);
			if (e.data.context._thirdFunc) e.data.context._thirdFunc.f.apply(e.data.context._thirdFunc.s || window, e.data.context._thirdFunc.a || []);
		};

		function preventDefault(e) { if (e.preventDefault) e.preventDefault(); return false }

		el._addAttrToForm = function (s) {
			this._form.setAttribute('btn-that-submitted', s);
		};

		el.show = function (delay) {
			this.oldShow();
			TweenLite.to(this, .5, {
				autoAlpha: 1, scaleX: 1, scaleY: 1, delay: delay ? delay : 0, ease: Back.easeOut, onCompleteScope: this, onStartScope: this,
				onComplete: function () {
					this._yesBtn.on('mouseup', { context: this }, onYes);
					this._noBtn.on('mouseup', { context: this }, onNo);
					if (this._thirdBtn) this._thirdBtn.on('mouseup', { context: this }, onThird);
				},
				onStart: function () {
					TweenLite.set(this, { marginTop: -((this.clientHeight / 2) / parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace('px', ''))) + 'em' });
					app.sounds.alertOn.play({ volume: 0.2 });
				}
			});
			return this;
		}

		el.hide = function () {
			this._yesBtn.off('mouseup', onYes);
			this._noBtn.off('mouseup', onNo);
			app.sounds.alertOff.play({ volume: 0.07 });
			createjs.Sound.play('alertOff', { volume: 0.2, delay: 150, interrupt: createjs.Sound.INTERRUPT_LATE });
			if (this._thirdBtn) this._thirdBtn.off('mouseup', onThird);
			TweenLite.to(this, .4, {
				autoAlpha: 0, scaleX: .8, scaleY: .8, ease: Back.easeIn, onCompleteScope: this,
				onComplete: function () {
					if (this._data.remove) this.remove(); else this.oldHide();
					if (this._offFunc) this._offFunc.f.apply(this._offFunc.s || window, this._offFunc.a || []);
				}
			});
			return this;
		}

		el.text = function (txt) {
			this._text.innerHTML = txt;
			return this;
		}

		el.setBtns = function (obj1, obj2, obj3) //obj:{text:string, icon:string, color:string, href:string, func:{func:function, params:array, scope:object}}
		{
			if (obj1) {
				var icon = obj1.icon || 'check', //(text,href,icon,color,inDiv,isHidden) 
					btn = obj3 ? (this._thirdBtn = app.UIBtn(obj1.text, obj1.href, icon, obj1.color)) : this._yesBtn;
				this._setFunc = obj3 ? this.thirdFunc : this.yesFunc;
				if (obj1.func) this._setFunc(obj1.func, obj1.params, obj1.scope);
				if (this._thirdBtn) {
					this.innsertBefore(this._thirdBtn, this._text);
				} else {
					if (obj1.text) btn.text(obj1.text);
					if (obj1.icon) btn.icon(obj1.icon);
					if (obj1.color) btn.color(obj1.color);
					if (obj1.href) btn.href(obj1.href);
				}
				delete this._setFunc;
			}
			if (obj2) {
				var icon = obj3 ? 'check' : 'close',
					btn = obj3 ? this._yesBtn : this._noBtn;
				this._setFunc = obj3 ? this.yesFunc : this.noFunc;
				if (obj2.func) this._setFunc(obj2.func, obj2.params, obj2.scope);
				if (obj2.icon) btn.icon(obj2.icon);
				if (obj2.text) btn.text(obj2.text);
				if (obj2.color) btn.color(obj2.color);
				if (obj2.href) btn.href(obj2.href);
				delete this._setFunc;
			}
			if (obj3) {
				var icon = obj3.icon || 'close';
				if (obj3.func) this.noFunc(obj3.func, obj3.params, obj3.scope);
				if (obj3.icon) this._noBtn.icon(obj3.icon);
				if (obj3.text) this._noBtn.text(obj3.text);
				if (obj3.color) this._noBtn.color(obj3.color);
				if (obj3.href) this._noBtn.href(obj3.href);
			}
			if (obj1.href || obj2.href || (obj3 && obj3.href)) this._form.on('submit', preventDefault);
			return this;
		};

		el.setForm = function (formID, func, doesThirdSubmit) {
			var ctx = this, attrObj = { form: formID, type: 'submit', name: 'yesBtn' };
			this._form.id = formID;
			this._form.off('submit', preventDefault);
			this._setFormFunc = function (e) {
				// if return/enter is used instead of btn
				if (!ctx._form.attr('btn-that-submitted')) ctx._form.setAttribute('btn-that-submitted', 'yesBtn');
				func(e);
				ctx.hide(e);
				if (e.preventDefault) e.preventDefault(); else return false;
			}
			this._form.on('submit', this._setFormFunc);
			this._yesBtn.attr(attrObj);
			this.yesFunc(this._addAttrToForm, ['yesBtn'], this);
			attrObj.name = 'noBtn';
			this._noBtn.attr(attrObj);
			this.noFunc(this._addAttrToForm, ['noBtn'], this);
			if (this._doesThirdSubmit && this._thirdBtn) {
				attrObj.name = 'thirdBtn';
				this._thirdBtn.attr(attrObj);
				this.thirdFunc(this._addAttrToForm, ['thirdBtn'], this);
			}
			return this;
		};

		el.reset = function () {
			this._offFunc = null;
			this._yesFunc = null;
			this._noFunc = null;
			this._yesBtn.removeAttribute('form');
			this._yesBtn.removeAttribute('type');
			this._noBtn.removeAttribute('form');
			this._noBtn.removeAttribute('type');
			this._yesBtn.innerHTML = '<span class="btn-icon-sprite check"></span>' + app.lang === 'en' ? 'YES' : 'SÍ';
			this._noBtn.innerHTML = '<span class="btn-icon-sprite close"></span>NO';
			this._yesBtn.off('mouseup', onYes);
			this._noBtn.off('mouseup', onNo);
			if (this._thirdBtn) this._thirdBtn.off('mouseup', onThird);
			if (this._thirdBtn) {
				this.removeChild(this._thirdBtn);
				this._thirdFunc = null;
				this._thirdBtn = null;
			}
			this._form.off('submit', this._setFormFunc);
			this._form.removeAttr('id').removeAttr('btn-that-submitted');
			TweenLite.set(this, { alpha: 0, scaleX: .8, scaleY: .8, display: 'none' });
			return this;
		}

		el.offFunc = function (func, args, scp) { this._offFunc = { f: func, a: args, s: scp }; return this };
		el.yesFunc = function (func, args, scp) { this._yesFunc = { f: func, a: args, s: scp }; return this };
		el.noFunc = function (func, args, scp) { this._noFunc = { f: func, a: args, s: scp }; return this };
		el.thirdFunc = function (func, args, scp) { this._thirdFunc = { f: func, a: args, s: scp }; return this };
		return el;
	};

}());

(function () {
	"use strict";
	var letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
	window.app.QuestionBox = function (question, answersArray, removeOnHide) {
		var el = app.NewComponent('div', { class: 'act-alert act-question-box box-shadow' });
		el.oldHide = el.hide;
		el.oldShow = el.show;
		el._data.remove = removeOnHide;
		el._text = document.createElement('p');
		el._text.innerHTML = question;
		el._text.className = 'act-alert-txt';
		el.appendChild(el._text);
		el._btns = [];
		for (var i = 0; i < answersArray.length; i++) {
			el._btns[i] = app.UIBtn(letters[i], null, 'none');
			el._btns[i].data('answer', answersArray[i]);
			el.appendChild(el._btns[i]);
			var p = document.createElement('p');
			p.innerHTML = answersArray[i];
			p.className = 'act-question-box-txt';
			el.appendChild(p);
		};
		el.answer = undefined;
		el._offFunc = undefined;
		el._answrFunc = undefined;
		el.oldHide().css({ alpha: 0, scaleX: .8, scaleY: .8, transformOrigin: "center center" });

		function onAnswer(e) {
			e.data.context.answer = e.currentTarget.data('answer');
			e.data.context.hide(e);
			if (e.data.context._answrFunc) e.data.context._answrFunc.f.apply(e.data.context._answrFunc.s || window, e.data.context._answrFunc.a || []);
		}

		el.show = function (delay) {

			this.oldShow();
			TweenLite.to(this, .5, {
				autoAlpha: 1, scaleX: 1, scaleY: 1, delay: delay ? delay : 0, ease: Back.easeOut, onCompleteScope: this, onStartScope: this,
				onComplete: function () {
					for (var i = 0; i < this._btns.length; i++) {
						this._btns[i].on(app.RELEASE, { context: this }, onAnswer);
					};
				},
				onStart: function () {
					TweenLite.set(this, { marginTop: -((this.clientHeight / 2) / parseFloat(window.getComputedStyle(document.body, null).getPropertyValue('font-size').replace('px', ''))) + 'em' });
					app.sounds.alertOn.play({ volume: 0.2 });
				}
			});
			return this;
		}

		el.hide = function () {
			for (var i = 0; i < this._btns.length; i++) {
				this._btns[i].off(app.RELEASE, { context: this }, onAnswer);
			};
			app.sounds.alertOff.play({ volume: 0.07 });
			createjs.Sound.play('alertOff', { volume: 0.2, delay: 150, interrupt: createjs.Sound.INTERRUPT_LATE });
			TweenLite.to(this, .4, {
				autoAlpha: 0, scaleX: .8, scaleY: .8, ease: Back.easeIn, onCompleteScope: this,
				onComplete: function () {
					if (this._data.remove) this.remove(); else this.oldHide();
					if (this._offFunc) this._offFunc.f.apply(this._offFunc.s || window, this._offFunc.a || []);
				}
			});
			return this;
		}

		el.update = function (question, answersArray) {
			this.innerHTML = '';
			this._text = document.createElement('p');
			this._text.innerHTML = question;
			this._text.className = 'act-alert-txt';
			this.appendChild(this._text);
			this._btns = [];
			for (var i = 0; i < answersArray.length; i++) {
				this._btns[i] = app.UIBtn(letters[i], null, 'none');
				this._btns[i].data('answer', answersArray[i]);
				this.appendChild(this._btns[i]);
				var p = document.createElement('p');
				p.innerHTML = answersArray[i];
				p.className = 'act-question-box-txt';
				this.appendChild(p);
			};
			this.answer = undefined;
			return this;
		}

		el.reset = function () {
			this._offFunc = null;
			this._answrFunc = null;
			this.innerHTML = '';
			TweenLite.set(this, { alpha: 0, scaleX: .8, scaleY: .8, display: 'none' });
		}

		el.offFunc = function (func, args, scp) { this._offFunc = { f: func, a: args, s: scp }; return this };
		el.answerFunc = function (func, args, scp) { this._answrFunc = { f: func, a: args, s: scp }; return this };
		return el;
	};

}());

(function () {
	"use strict";
	window.app.MakeCuepointMedia = function (media, addListeners, tolerance) {
		var context = media,
			cuepoints = [],
			cpMode = "event",
			cpNav, cpIndex = 0,
			l = 0, n, i, navTimer,
			hasListeners,
			al = addListeners || true,
			isAndroid = (function () {
				if (window.navigator.platform && window.navigator.platform === 'Android') return true;
				else if (window.navigator.userAgent.toLowerCase().search('android') !== -1) return true;
				else if (window.navigator.appVersion.toLowerCase().search('android') !== -1) return true;
				else return false;
			}());
		//
		media.tolerance = tolerance || .3;
		//
		Object.defineProperty(media, 'hasListeners', {
			get: function () { return hasListeners },
			set: function () { throw new Error('CuepointMedia.hasListeners is a read only property.') }
		});
		Object.defineProperty(media, 'isCuepointMedia', {
			get: function () { return true },
			set: function () { throw new Error('CuepointMedia.isCuepointMedia is a read only property.') }
		});
		//	
		media.addCuepointListeners = function () {
			//console.log('addCuepointListeners()')
			hasListeners = true;
			this.addEventListener("seeked", onMediaSeeked, false);
			// "seeked" event unreliable on Android add "seeking" listener to help out
			if (isAndroid) this.addEventListener("seeking", onMediaSeeked, false);
			this.addEventListener("playing", onMediaPlaying, false);
			if (!app.video.paused) requestAF(update);
			return this;
		};

		media.removeCuepointListeners = function () {
			//console.log('removeCuepointListeners()')
			hasListeners = false;
			this.removeEventListener("seeked", onMediaSeeked);
			this.removeEventListener("seeking", onMediaSeeked);
			this.removeEventListener("playing", onMediaPlaying);
			cancelAF(update);
			return this;

		};
		media.addCuepoint = function (time, kind, name, func, args, scope) {
			cuepoints.push({ t: time, k: kind, id: name, f: func, a: args, s: scope, executed: false });
			cuepoints.sort(function (a, b) { return parseFloat(a.t) - parseFloat(b.t) });
			l = cuepoints.length; //console.log(cuepoints);
			return this;
		};
		//
		media.seekCuepoint = function (name) {
			for (n = 0; l > n; n++) {
				if (cuepoints[n].id === name) {
					//console.log('\t'+ cuepoints[n].id);
					if (cuepoints[n].k === "nav" || cuepoints[n].k === "both") {
						//console.log('\t\t'+ cuepoints[n].k);
						cpMode = "nav";
						if (this.currentTime >= cuepoints[n].t && this.currentTime < cuepoints[n].t + this.tolerance) {
							//console.log('seek: stay and execute');
							cuepoints[n].f.apply(cuepoints[n].s || context, cuepoints[n].a || []);
							cpMode = "event";
							requestAF(update);
						} else {
							//console.log('seek: set cpNav and change currentTime to '+cuepoints[n].t);
							cpNav = cuepoints[n];
							this.currentTime = cuepoints[n].t;
						};
						break;
					};
				};
			};
			return this;
		};
		//
		function update() {
			//console.log("CuepointMedia.update()", context.currentTime);
			if (context.paused || context.ended) return false;
			else if (l > 0 && cpMode === "event") {
				for (i = 0; l > i; i++) {
					if (context.currentTime >= cuepoints[i].t && context.currentTime < cuepoints[i].t + context.tolerance) {
						if ((cuepoints[i].k === "event" || cuepoints[i].k === "both") && !cuepoints[i].executed) {
							//console.log("on cp event", context.currentTime);
							//console.log("\tcuepoint: "+cuepoints[i].id, cuepoints[i].k, cuepoints[i].t, cuepoints[i].executed);
							cuepoints[i].f.apply(cuepoints[i].s || context, cuepoints[i].a || []);
							cuepoints[i].executed = true;
							var cp = cuepoints[i];
							TweenLite.delayedCall(.5, function () { cp.executed = false; });
						};
						break;
					};
				};
			};
			if (hasListeners) requestAF(update);
		};
		//
		function onNavTimer() {
			//console.log("CuepointMedia.onNavTimer()", context.currentTime);
			if (cpNav === null || cpNav === undefined) {
				clearInterval(navTimer);
			} else {
				if (context.currentTime >= cpNav.t - .2 && context.currentTime < cpNav.t + context.tolerance) {
					// on nav cuepoint
					//console.log("on cp nav", context.currentTime);
					//console.log("\tcuepoint: "+cpNav.id, cpNav.k, cpNav.t);
					if (app.loadAnim) app.loadAnim.hide();
					cpNav.f.apply(cpNav.s || context, cpNav.a || []);
					cpNav = null;
					clearInterval(navTimer);
					//TweenLite.delayedCall(.4,function(){cpMode = "event"});
					cpMode = "event";
					if (hasListeners) requestAF(update);
				};
			};
		};
		//
		function onMediaSeeked(e) { if (cpMode === "nav" && cpNav !== null) navTimer = setInterval(onNavTimer, 17) };
		//
		function onMediaPlaying(e) { if (hasListeners) requestAF(update) };
		//
		if (al) media.addCuepointListeners();
		return media;
	};
}());

(function () {
	"use strict";
	window.app.EmoVolume = function (player) {
		var el = app.NewComponent('div', { class: 'act-volume', 'data-type': player });
		el.oldHide = el.hide;
		el.oldShow = el.show;
		el.groove = document.createElement('div');
		el.groove.className = 'groove';
		el.level = app.NewComponent('div', { class: 'level' });
		el.handle = app.NewComponent('div', { class: 'handle' });
		el.bar = app.NewComponent('div', { class: 'bar' });
		el.handle.appendChild(el.bar);
		el.groove.appendChild(el.level);
		el.appendChild(el.groove);
		el.appendChild(el.handle);
		//
		el.ratio = 28.2;
		el.percent = 0;
		el.prevPercent = undefined;
		el.pan = player === 'cadet' ? -0.9 : 0.9;
		//
		function update() {
			this.prevPercent = this.percent;
			if (this.level._gsTransform) this.percent = Math.abs(this.level._gsTransform.yPercent);
			if (this.percent > this.prevPercent) createjs.Sound.play("meterUp", { pan: this.pan });
			else if (this.percent < this.prevPercent) createjs.Sound.play("meterDown", { pan: this.pan });
		}
		//
		el.dim = function (alpha) {
			alpha = alpha ? alpha : .5;
			this.setAttribute('disabled', 'disabled');
			TweenLite.to(this, .25, { opacity: alpha, onStart: this.oldShow, onStartScope: this });
			return this;
		}
		//
		el.show = function (delay) {
			TweenLite.to(this, .5, { opacity: 1, delay: delay ? delay : 0, ease: Power2.easeOut, onStart: this.oldShow, onStartScope: this });
			return this;
		}
		//
		el.hide = function (delay) {
			TweenLite.to(this, .5, { opacity: 0, delay: delay ? delay : 0, ease: Power2.easeIn, onComplete: this.oldHide, onCompleteScope: this });
			return this;;
		}
		// per: Number (0-1), setHandle: Boolean (default = false), time: Number, vars: TweenMax vars object (no need to have y prop, default ease is Power2.easeOut)
		el.setLevel = function (per, setHandle, time, vars) {
			if (!vars) vars = {};
			var p = -per * 100,
				h = this.bar.clientHeight !== 0 ? this.bar.clientHeight : 7,
				u = vars.onUpdate || undefined,
				f = time ? TweenMax.to : TweenMax.set,
				s = vars.onUpdateScope || this,
				a = vars.onUpdateArgs || [],
				uf = u ? function () { update.apply(this, []); u.apply(s, a) } : update,
				a1, a2 = [this.handle, { y: (-h * this.ratio) * per, force3D: true }];
			//
			for (var arg in vars) a2[1][arg] = vars[arg];
			vars.y = p + '%';
			//
			if (time) {
				if (!vars.ease) {
					vars.ease = Power2.easeOut;
					a2[1].ease = vars.ease;
				}
				vars.onUpdate = uf;
				vars.onUpdateScope = s;
				a1 = [this.level, time, vars];
				a2.splice(1, 0, time);
			} else {
				a1 = [this.level, vars];
			}
			//
			f.apply(TweenMax, a1);
			if (setHandle) f.apply(TweenMax, a2);
			if (!vars.time) uf.apply(s, a);
			return this;
		}
		return el;
	};

}());

