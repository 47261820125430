(function () {
  "use strict";
  var p = '<-:JSON';
  //
  function validateUser() {
    var currentCookie = getCookie('sfp-validate');
    if (currentCookie !== sessionStorage.storedCookie) {
      sessionStorage.clear();
      sessionStorage.storedCookie = currentCookie;
    }
  }

  function getCookie(name) {
    var n = name + '=',
      ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(n) == 0) return c.substring(n.length, c.length);
    }
    return null;
  }

  function makeCookie(str) {
    var d = new Date(),
      t = d.getTime() + '.' + str,
      x;
    d.setTime(d.getTime() + (0.5 * 60 * 60 * 1000)); // allow 30 min per page before session ends
    x = 'expires=' + d.toUTCString();
    document.cookie = 'sfp-validate=' + t + '; ' + x + ('; path=/');
    sessionStorage.storedCookie = getCookie('sfp-validate');
  }

  /* function encode(val) {
    var v = '',
      l = val.length;
    for (var i = 0; i < l; i++) {
      var s = val.charCodeAt(i).toString();
      v += s.length + s;
    };
    return v;
  } */

  /* function decode(val) {
    var v = '',
      s, c = 0,
      l = val.length;
    for (var i = 0; i < l; i++) {
      if (c === 0) {
        c = parseInt(val.charAt(i));
        if (s) v += String.fromCharCode(parseInt(s));
        s = '';
      } else {
        s += val.charAt(i);
        c--;
      }
    };
    v += String.fromCharCode(s);
    return v;
  } */

  window.app.storage = {
    init: function (str) {
      sessionStorage.clear();
      sessionStorage.id = str;
      makeCookie(str);
      return this;
    },
    updateCookie: function () {
      if (!getCookie('sfp-validate')) window.location.pathname = '/dotNet/logout.aspx';
      makeCookie(sessionStorage.id);
      return this;
    },
    save: function (key, val) {
      if (val === undefined || val === null) return this;
      validateUser();
      /* if (typeof val === 'string') val = encode(val);
      else val = encode(JSON.stringify(val) + p); */
      if (typeof val !== 'string') val = JSON.stringify(val) + p;
      /* if (val !== undefined || val !== null) sessionStorage[encode(key)] = val; */
      if (val !== undefined || val !== null) {
        if (key === 'lang') localStorage[key] = val;
        else sessionStorage[key] = val;
      } 
      return this;
    },
    get: function (key) {
      validateUser();
      /* var v = decode(sessionStorage[encode(key)]); */
      var v = sessionStorage[key] || localStorage[key];
      if (v && v.search(p) !== -1) v = JSON.parse(v.replace(p, ''));
      //else v = decode(v);
      return v;
    },
    remove: function (key) {
      /* sessionStorage.removeItem(encode(key)); */
      if (key === 'lang') localStorage.removeItem(key);
      else sessionStorage.removeItem(key);
      return this;
    },
    clear: function () {
      sessionStorage.clear();
      document.cookie = 'sfp-validate=;expires=' + new Date().toUTCString();
      return this;
    }
  };
  if (app.page === 'index') app.storage.clear();
  else if (app.session) app.avatars = app.storage.get('avatars') || ['cadet-0, captain-0'];
}());