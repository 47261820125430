if (app.video)(function (video) {
  "use strict";
  var context = video, m3u8, path, playBtn, pauseBtn, hotspot;
  //
  function makeControls(remove) {
    function removeCtrls() {
      hotspot.off(app.RELEASE, context.playBtnToggle);
      playBtn.remove();
      hotspot.remove();
      playBtn = hotspot = null;
    }
    //
    playBtn = $('<div class="play-btn">');
    pauseBtn = $('<div class="pause-btn" style="display:none;">');
    hotspot = $('<div class="vid-hotspot">');
    app.ui.find('.center').append(hotspot.append(playBtn.append(pauseBtn)));
    TweenLite.set(playBtn, {
      transformOrigin: 'center center'
    });
    playBtn.isOn = true;
    playBtn.animIn = function () {
      TweenLite.to(playBtn, 0.4, {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        ease: Back.easeOut,
        onComplete: pauseBtn.hide,
        onCompleteScope: pauseBtn,
        onStart: function () {
          pauseBtn.show();
          playBtn.show();
          playBtn.isOn = true;
        }
      });
    };
    playBtn.animOut = function () {
      playBtn.isOn = false;
      TweenLite.to(playBtn, 0.4, {
        opacity: 0,
        scaleX: 0.85,
        scaleY: 0.85,
        ease: Back.easeIn,
        onComplete: (remove ? removeCtrls : playBtn.hide),
        onCompleteScope: (remove ? null : playBtn)
      });
      var t = video.currentTime,
        s = setInterval(function () {
          if (t === video.currentTime) {
            app.loadAnim.show();
            clearInterval(s);
          }
        }, 1000 / 30);
    };
  }
  //
  if (video.getAttribute('data-controls') !== 'false') makeControls();
  //
  video.hotspot = hotspot;
  video.init = function (play) {
    //console.log('VideoPlayer.init()');
    if (!playBtn && app.isTouchDevice) makeControls(true);
    if (!app.loadAnim) app.ui.find('.center').append(app.loadAnim = app.LoadAnim([255, 255, 255]));
    if (hotspot) {
      hotspot.on(app.RELEASE, this.playBtnToggle);
      var note = app.Note(app.lang === 'en' ? 'Pause or Play:<br>Press on the video area.' : 'Pausa o Reproduce:<br>Presione en el área de video.', 'top', 2, false).css({
        top: '25em',
        left: '24.4em'
      });
      hotspot.append(note.show());
      note.remove(true, 4);
    }
    this.addEventListener("ended", onVideoComplete, false);
    this.addEventListener("waiting", onVideoWaiting, false);
    this.addEventListener("playing", onVideoPlaying, false);
    this.addSource(app.page, (play || true));
    return this;
  };
  video.playBtnToggle = function (e) {
    //console.log('video.playBtnToggle', context.paused || context.ended);
    if (context.paused || context.ended) {
      context.play();
      app.loadAnim.show();
      playBtn.animOut();
      if (e) app.sounds.beep2.play({
        volume: 0.1
      });
    } else {
      context.pause();
      app.loadAnim.hide();
      if (playBtn) playBtn.animIn();
      if (e) app.sounds.beep1.play({
        volume: 0.1
      });
    }
    return this;
  };

  video.addSource = function (file, play) {
    //console.log('VideoPlayer.addSource()',file);
    this.innerHTML = '';
    path = `${app.videoDomain}${file}_${app.lang}/master.m3u8`;
    //
    if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(path);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        if (play) video.play();
      });
    } else {
      m3u8 = document.createElement('source');
      m3u8.src = path;
      m3u8.type = "application/x-mpegURL";
      this.appendChild(m3u8);
      //
      this.load();
      if (play) this.play();
    }

    return this;
  };
  //
  function onVideoComplete(e) {
    app.mainBtn.animIn();
  }
  //
  function onVideoPlaying(e) {
    if (playBtn && playBtn.isOn) playBtn.animOut();
    if (video.className.search('offstage') !== -1) video.className = video.className.replace('offstage', '');
    var t = video.currentTime,
      s = setInterval(function () {
        if (t < video.currentTime) {
          app.loadAnim.hide();
          clearInterval(s);
        }
      }, 1000 / 30);
  }
  //
  function onVideoWaiting(e) {
    app.loadAnim.show();
  }

}(app.video));