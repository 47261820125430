(function () {
  "use strict";
  var map = {
    home: '/home.aspx',
    next: undefined,
    session1: [{
        path: '/session1/a_s1_intro.aspx',
        name: {
          es: 'Introducción del Nivel 1',
          en: 'Stage 1 Intro'
        }
      },
      {
        path: '/session1/a_parent_quiz.aspx',
        game: true,
        name: {
          es: 'Cuestionario para Padres',
          en: 'Parent Quiz'
        }
      },
      {
        path: '/session1/a_engagement.aspx',
        name: {
          es: 'Compromiso',
          en: 'Engagement'
        }
      },
      {
        path: '/session1/a_participate.aspx',
        name: {
          es: 'Consejos para la Participación',
          en: 'Tips for Participation'
        }
      },
      {
        path: '/session1/v_s1_wrap.aspx',
        name: {
          es: 'Resumen del Nivel 1',
          en: 'Stage 1 Wrap-Up'
        }
      },
    ],
    session2: [{
        path: '/session2/a_avatars.aspx',
        name: {
          es: 'Elige Sus Avatares',
          en: 'Choose Your Avatars'
        }
      },
      {
        path: '/session2/v_s2_intro.aspx',
        name: {
          es: 'Introducción del Nivel 2',
          en: 'Stage 2 Intro'
        }
      },
      {
        path: '/session2/v_values_intro.aspx',
        name: {
          es: 'Introducción de Llegar a Conocerte',
          en: 'Getting To Know You Intro'
        }
      },
      {
        path: '/session2/a_values.aspx',
        game: true,
        name: {
          es: 'Juego de Llegar a Conocerte',
          en: 'Getting To Know You Game'
        }
      },
      {
        path: '/session2/v_values_wrap.aspx',
        name: {
          es: 'Resumen de Llegar a Conocerte',
          en: 'Getting To Know You Wrap-Up'
        }
      },
      {
        path: '/session2/v_talkover_intro.aspx',
        name: {
          es: 'Introducción de Hablar por Encima',
          en: 'Talkover Intro'
        }
      },
      {
        path: '/session2/a_talkover.aspx',
        game: true,
        name: {
          es: 'Juego de Hablar por Encima',
          en: 'Talkover Game'
        }
      },
      {
        path: '/session2/v_talkover_wrap.aspx',
        name: {
          es: 'Resumen de Hablar por Encima',
          en: 'Talkover Wrap-Up'
        }
      },
      {
        path: '/session2/v_gesture_intro.aspx',
        name: {
          es: 'Introducción de Gesto',
          en: 'Gesture Intro'
        }
      },
      {
        path: '/session2/a_gesture.aspx',
        game: true,
        name: {
          es: 'Juego de Gesto',
          en: 'Gesture Game'
        }
      },
      {
        path: '/session2/v_gesture_wrap.aspx',
        name: {
          es: 'Resumen de Gesto',
          en: 'Gesture Wrap-Up'
        }
      },
      {
        path: '/session2/v_brainstorm_intro.aspx',
        name: {
          es: 'Introducción de Lluvia de Ideas',
          en: 'Feelings Brainstorm Intro'
        }
      },
      {
        path: '/session2/a_brainstorm.aspx',
        game: true,
        name: {
          es: 'Juego de Lluvia de Ideas',
          en: 'Feelings Brainstorm Game'
        }
      },
      {
        path: '/session2/a_brainstorm_wrap.aspx',
        name: {
          es: 'Resumen de Lluvia de Ideas',
          en: 'Feelings Brainstorm Wrap-Up'
        }
      },
      {
        path: '/session2/v_s2_wrap.aspx',
        name: {
          es: 'Resumen del Nivel 2',
          en: 'Stage 2 Wrap-Up'
        }
      },
    ],
    session3: [{
        path: '/session3/v_s3_intro.aspx',
        name: {
          es: 'Introducción del Nivel 3',
          en: 'Stage 3 Intro'
        }
      },
      {
        path: '/session3/v_myths_intro.aspx',
        name: {
          es: 'Introducción de Aplastantes de Mitos Emocionales',
          en: 'Emotion Myth Smashers Intro'
        }
      },
      {
        path: '/session3/a_myths.aspx',
        game: true,
        name: {
          es: 'Juego de Aplastantes de Mitos Emocionales',
          en: 'Emotion Myth Smashers Game'
        }
      },
      {
        path: '/session3/v_myths_wrap.aspx',
        name: {
          es: 'Resumen de Aplastantes de Mitos Emocionales',
          en: 'Emotion Myth Smashers Wrap-Up'
        }
      },
      {
        path: '/session3/a_behavior.aspx',
        game: true,
        name: {
          es: 'Juego de Por qué',
          en: 'The Why Game'
        }
      },
      {
        path: '/session3/v_behavior_wrap.aspx',
        name: {
          es: 'Resumen de Por qué',
          en: 'The Why Game Wrap-Up'
        }
      },
      {
        path: '/session3/a_bodytalk_intro.aspx',
        name: {
          es: 'Introducción de Tirador',
          en: 'Slingshot Intro'
        }
      },
      {
        path: '/session3/a_bodytalk.aspx',
        game: true,
        name: {
          es: 'Juego de Tirador',
          en: 'Slingshot Game'
        }
      },
      {
        path: '/session3/v_bodytalk_wrap.aspx',
        name: {
          es: 'Resumen de Tirador',
          en: 'Slingshot Wrap-Up'
        }
      },
      {
        path: '/session3/v_s3_wrap.aspx',
        name: {
          es: 'Resumen del Nivel 3',
          en: 'Stage 3 Wrap-Up'
        }
      }
    ],
    session4: [{
        path: '/session4/v_s4_intro.aspx',
        name: {
          es: 'Introducción del Nivel 4',
          en: 'Stage 4 Intro'
        }
      },
      {
        path: '/session4/v_strategies_intro.aspx',
        name: {
          es: 'Introducción de Asteroide',
          en: 'Asteroid Intro'
        }
      },
      {
        path: '/session4/a_strategies.aspx',
        game: true,
        name: {
          es: 'Juego de Asteroide',
          en: 'Asteroid Game'
        }
      },
      {
        path: '/session4/a_strategies_wrap.aspx',
        name: {
          es: 'Resumen de Asteroide',
          en: 'Asteroid Wrap-Up'
        }
      },
      {
        path: '/session4/v_glt1_intro.aspx',
        name: {
          es: 'Introducción de Déjalo, Suéltalo, Piénsalo',
          en: 'Get It Out, Let It Out, Think It Out Intro'
        }
      },
      {
        path: '/session4/a_glt1.aspx',
        game: true,
        name: {
          es: 'Juego de Déjalo, Suéltalo, Piénsalo',
          en: 'Get It Out, Let It Out, Think It Out Game'
        }
      },
      {
        path: '/session4/v_glt1_wrap.aspx',
        name: {
          es: 'Resumen de Déjalo, Suéltalo, Piénsalo',
          en: 'Get It Out, Let It Out, Think It Out Wrap-Up'
        }
      },
      {
        path: '/session4/v_boundaries_intro.aspx',
        name: {
          es: 'Introducción de Límites',
          en: 'Boundaries Intro'
        }
      },
      {
        path: '/session4/a_boundaries.aspx',
        game: true,
        name: {
          es: 'Juego de Límites',
          en: 'Boundaries Game'
        }
      },
      {
        path: '/session4/v_boundaries_wrap.aspx',
        name: {
          es: 'Resumen de Límites',
          en: 'Boundaries Wrap-Up'
        }
      },
      {
        path: '/session4/v_s4_wrap.aspx',
        name: {
          es: 'Resumen de Nivel 4',
          en: 'Stage 4 Wrap-Up'
        }
      }
    ],
    session5: [{
        path: '/session5/v_s5_intro.aspx',
        name: {
          es: 'Introducción del Nivel 5',
          en: 'Stage 5 Intro'
        }
      },
      {
        path: '/session5/a_stresscomm_intro.aspx',
        name: {
          es: 'Introducción de la Comunicación Estresante',
          en: 'Stressful Communication Intro'
        }
      },
      {
        path: '/session5/a_stresscomm.aspx',
        game: true,
        name: {
          es: 'Juego de la Comunicación Estresante',
          en: 'Stressful Communication Game'
        }
      },
      {
        path: '/session5/a_stresscomm_wrap.aspx',
        name: {
          es: 'Resumen de la Comunicación Estresante',
          en: 'Stressful Communication Wrap-Up'
        }
      },
      {
        path: '/session5/v_anatomy_intro.aspx',
        name: {
          es: 'Introducción de Anatomía',
          en: 'Anatomy Intro'
        }
      },
      {
        path: '/session5/a_anatomy.aspx',
        game: true,
        name: {
          es: 'Juego de Anatomía',
          en: 'Anatomy Game'
        }
      },
      {
        path: '/session5/v_anatomy_wrap.aspx',
        name: {
          es: 'Resumen de Anatomía',
          en: 'Anatomy Wrap-Up'
        }
      },
      {
        path: '/session5/v_whizquiz_intro.aspx',
        name: {
          es: 'Introducción de Aplastantes de Mitos Sexuales',
          en: 'Sexual Myth Smashers Intro'
        }
      },
      {
        path: '/session5/a_whizquiz.aspx',
        game: true,
        name: {
          es: 'Juego de Aplastantes de Mitos Sexuales',
          en: 'Sexual Myths Smashers Game'
        }
      },
      {
        path: '/session5/v_whizquiz_wrap.aspx',
        name: {
          es: 'Resumen de Aplastantes de Mitos Sexuales',
          en: 'Sexual Myths Smashers Wrap-Up'
        }
      },
      {
        path: '/session5/v_s5_wrap.aspx',
        name: {
          es: 'Resumen del Nivel 5',
          en: 'Stage 5 Wrap-Up'
        }
      }
    ],
    session6: [{
        path: '/session6/v_s6_intro.aspx',
        name: {
          es: 'Introducción del Nivel 6',
          en: 'Stage 6 Intro'
        }
      },
      {
        path: '/session6/a_condoms_intro.aspx',
        name: {
          es: 'Introducción de Condones 101',
          en: 'Condoms 101 Intro'
        }
      },
      {
        path: '/session6/v_condoms.aspx',
        game: true,
        name: {
          es: 'Video de Condones 101',
          en: 'Condoms 101 Video'
        }
      },
      {
        path: '/session6/a_mistakes_intro.aspx',
        name: {
          es: 'Introducción de Errores de Condón',
          en: 'Condom Mistakes Intro'
        }
      },
      {
        path: '/session6/a_mistakes.aspx',
        game: true,
        name: {
          es: 'Juego de Errores de Condón',
          en: 'Condom Mistakes Game'
        }
      },
      {
        path: '/session6/a_mistakes_wrap.aspx',
        name: {
          es: 'Resumen de Errores de Condón',
          en: 'Condom Mistakes Wrap-up'
        }
      },
      {
        path: '/session6/v_scenarios_intro.aspx',
        name: {
          es: 'Introducción de Dante y María (Parte 1)',
          en: 'Jeff and Maria Intro (Part 1)'
        }
      },
      {
        path: '/session6/a_scenarios1.aspx',
        game: true,
        name: {
          es: 'Juego de Dante y María (Parte 1)',
          en: 'Jeff and Maria Game (Part 1)'
        }
      },
      {
        path: '/session6/v_scenarios2_intro.aspx',
        name: {
          es: 'Introducción de Dante y María (Parte 2)',
          en: 'Jeff and Maria Intro (Part 2)'
        }
      },
      {
        path: '/session6/a_scenarios2.aspx',
        game: true,
        name: {
          es: 'Juego de Dante y María (Parte 2)',
          en: 'Jeff and Maria Game (Part 2)'
        }
      },
      {
        path: '/session6/v_scenarios_wrap.aspx',
        name: {
          es: 'Resumen de Dante y María',
          en: 'Jeff and Maria Wrap-up'
        }
      },
      {
        path: '/session6/v_s6_wrap.aspx',
        name: {
          es: 'Resumen del Nivel 6',
          en: 'Stage 6 Wrap-Up'
        }
      }
    ]
  };
  //
  $('body').on('touchmove', function (e) {
    e.preventDefault();
  });
  //$('#home-btn').attr('href',map.home);
  //	
  window.Flow = {
    getNext: function () {
      if (app.session) {
        var a = map[app.session];
        for (var i = a.length - 1; i >= 0; i--) {
          if (a[i].path === window.location.pathname) {
            if (a[i + 1]) return a[i + 1].path;
            else return map.home;
          }
        }
      } else if (app.page === 'home') {
        return map.next;
      }
    },
    setNext: function (str) {
      map.next = str;
    },
    getPageInfo: function (sessionNameOrNum, index) {
      var s = typeof sessionNameOrNum === 'string' && sessionNameOrNum.length > 1 ? sessionNameOrNum : 'session' + sessionNameOrNum;
      if (index > map[s].length - 1) return map[s][0];
      return map[s][index];
    },
    getSessionInfo: function (sessionNameOrNum) {
      return map[(typeof sessionNameOrNum === 'string' && sessionNameOrNum.length > 1 ? sessionNameOrNum : 'session' + sessionNameOrNum)];
    },
    getPageIndex: function (sessionName, pathName) {
      var arr = map[sessionName];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].path === pathName) return i;
      }
      return undefined;
    }
  };
  //
  Object.defineProperty(Flow, 'home', {
    get: function () {
      return map.home;
    },
    set: function () {
      throw new Error('Flow.home is a read only property.');
    }
  });
}());